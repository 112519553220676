import accounting from '../vendor/accounting.min.js'

$(document).ready ->
  return unless (form = document.querySelector('form#edit_digibase_network_outgoing_unsent_posts_post_assignment'))
  return unless (hidden_kind_input = form.querySelector('input#digibase_network_outgoing_unsent_posts_post_assignment_kind'))

  # Toggle invoice fields depending on selected document kind
  mdc_select_kind_pair = window.mdc_selects.find (pair) -> pair.element == hidden_kind_input.parentElement
  mdc_select_kind_pair.mdc_select.listen 'MDCSelect:change', (event) ->
    invoice_div = form.querySelector('#edit_invoice_fields')
    if event.detail.value == 'invoice'
      invoice_div.classList.remove('hs-hidden')
    else
      invoice_div.classList.add('hs-hidden')

  # Calculate tax and gross amount depending on net amount input and selected tax percentage
  update_tax_and_gross_amount = (raw_tax_percentage) ->
    net_amount = accounting.parse($('#digibase_network_outgoing_unsent_posts_post_assignment_net_amount').val(), ',')
    tax_percentage = accounting.parse(raw_tax_percentage, ',')
    tax_amount = (net_amount * tax_percentage).toFixed(2)
    gross_amount = (Number(net_amount) + Number(tax_amount)).toFixed(2)
    tax_amount_field = $('#digibase_network_outgoing_unsent_posts_post_assignment_tax_amount')
    tax_amount_field.val(accounting.formatNumber(tax_amount, 2, '.', ','))
    gross_amount_field = $('#digibase_network_outgoing_unsent_posts_post_assignment_gross_amount')
    gross_amount_field.val(accounting.formatNumber(gross_amount, 2, '.', ','))

  return unless (hidden_tax_percentage_input = form.querySelector('input#digibase_network_outgoing_unsent_posts_post_assignment_tax_percentage'))

  mdc_select_tax_percentage_pair = window.mdc_selects.find (pair) -> pair.element == hidden_tax_percentage_input.parentElement
  net_amount_field = $('#digibase_network_outgoing_unsent_posts_post_assignment_net_amount')
  net_amount_field.change (event) ->
    update_tax_and_gross_amount(hidden_tax_percentage_input.value)

  mdc_select_tax_percentage_pair.mdc_select.listen 'MDCSelect:change', (event) ->
    update_tax_and_gross_amount(event.detail.value)
