/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//                     and the remaining question then would just be how to attach the relevant handlers to material design components.
import "../src/legacy_gem_import.js.erb";

import "../stylesheets/digibase_network.scss";
import "../stylesheets/digibase_mdc/action_text.scss";

import '../src/digibase_network/service_worker_registration.js';
import '../src/digibase_mdc/app_bar.js.coffee';
import '../src/digibase_mdc/shared.js.coffee';
import '../src/digibase_mdc/stripe_payments.js.coffee';
import '../src/digibase_network/post_assignment_invoice_fields.js.coffee';

require("jquery");
require("trix");
require("@rails/actiontext");

import Rails from "@rails/ujs";
Rails.start();
